
.barTopLeft {
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s ease;
    border-top-left-radius: 4%;
    border-bottom-left-radius: 4%;
}
    
.barMiddelLeft {
    width: 50%;
    position: absolute;
    left: 0;
    top: calc(50% - 2px);
    transition: all 0.3s ease;
    border-top-left-radius: 4%;
    border-bottom-left-radius: 4%;
}
    
.barBottomLeft {
    width: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.3s ease;
    border-top-left-radius: 4%;
    border-bottom-left-radius: 4%;
}
    
.barTopRight {
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s ease;
    border-top-right-radius: 4%;
    border-bottom-right-radius: 4%;
}
    
.barMiddelRight {
    width: 50%;
    height: 4px;
    position: absolute;
    right: 0;
    top: calc(50% - 2px);
    transition: all 0.3s ease;
    border-top-right-radius: 4%;
    border-bottom-right-radius: 4%;
}
    
.barBottomRight {
    width: 50%;
    height: 4px;
    position: absolute;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease;
    border-top-right-radius: 4%;
    border-bottom-right-radius: 4%;
}
    
.left {
    left: -100%;
}
    
.right {
    right: -100%;
}
    
.opacityNone {
    opacity: 0;
}
    
.displayNone {
    display: none;
}
    
.leftTopRotate {
    transform: rotate(45deg);
}
    
.rightTopRotate {
    transform: rotate(-45deg);
}
    
    
.topDown {
    top: 30.7%;
}
    
.bottomUp {
    bottom: 30.7%;
}
    
.leftIn {
    left: 10%;
}
    
.rightIn {
    right: 10%;
}