.embed-container {
  position: relative;

  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  width: 100%;
}

  
  